angular.module('eOpti.filters').filter('none', [
    function () {
        return function (input, none, mask) {
            if(parseInt(input) === parseInt(none)) {
                return typeof mask === 'undefined' ? 'brak' : mask;
            }

            return input;
        }
    }
]);