angular.module('eOpti.filters.time').filter('stringTimeWithoutYear', [function () {
    return function (input) {
        if (input && (input.length === 10 || input.length === 19)) {
            let result = '',
                tab    = input.split(' '),
                time   = tab[0].split('-'),
                date   = new Date(parseInt(time[0]), parseInt(time[1]) - 1, parseInt(time[2]))
            // na chromie wystarczy new Date(input) niestety safari jest głupie i potrzebuje to co jest wyżej ...

            if (date.getFullYear() !== new Date().getFullYear()) {
                result = date.getFullYear().toString() + '-'
            }

            return result + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }

        return input
    }
}])