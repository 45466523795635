angular.module('eOpti.filters').filter('count', [
    function () {
        return function (input) {
            if(typeof input === 'undefined') {
                return 0;
            }

            return ('' + input).length;
        };
    }
]);