angular.module('eOpti.filters.time').filter('secondsToHuman', ['$filter',
    function ($filter) {
        return function (seconds) {
            var datetime = new Date(1970, 0, 1).setSeconds(seconds),
                human = $filter('date')(datetime, 'HH:mm:ss');

            if(human.substring(0, 2) === '00') {
                human = human.substring(3);

                if(human.substring(0, 2) === '00') {
                    human = parseInt(human.substring(3)) + 's';
                }
            }

            return human;
        };
    }
]);