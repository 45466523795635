angular.module('eOpti.filters').filter('operatorsField', [
    function () {
        return function(operators, field, columns) {
            angular.forEach(columns, column => {
                if(column.id === field && ['select', 'rate'].includes(column.type)) {
                    operators = operators.slice(0, 6)
                }
            })

            return operators
        }
    }
])