angular.module('eOpti.filters').filter('chunk', [
    function () {
        return function (input, size) {
            if(typeof input === 'undefined') {
                return [];
            }

            var _return = [];

            for(var i = 0, j = input.length; i < j;  i += size) {
                _return.push(input.slice(i, i + size));
            }

            return _return;
        };
    }
]);