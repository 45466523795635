angular.module('eOpti.filters.receipt').filter('receiptDocument', [
    function () {
        return function (input, typeId) {
            var ids = [],
                options = [];

            switch(typeId) {
                case 'salary':
                case 'dispatch':
                    ids = [1, 3]; // faktura VAT, pokwitowanie

                    break;

                case 'office_items':
                case 'cleaning_items':
                    ids = [1, 2]; // faktura VAT, paragon fiskalny

                    break;

                case 'transport':
                    ids = [1]; // faktura VAT

                    break;

                case 'brochure':
                case 'travel':
                case 'advertisement':
                case 'shipping_pp':
                case 'rent':
                case 'phone':
                case 'terminal':
                case 'others':
                    ids = [1, 2, 3, 4, 5]; // wszystko

                    break;

                default:
                    ids = [1, 2, 3, 4, 5];
            }

            angular.forEach(ids, function(id) {
                angular.forEach(input, function(obj) {
                    if(obj.id == id) {
                        options.push(obj);
                    }
                });
            });

            return options;
        };
    }
]);