angular.module('eOpti.filters').filter('from', [function () {
    return function (items, from) {
        var filtered = [];

        angular.forEach(items, function(item, key) {
            if(key >= parseInt(from)) {
                filtered.push(item);
            }
        });

        return filtered;
    }
}
]);