angular.module('eOpti.filters.time').filter('day', ['$translate', '$rootScope',
    function ($translate, $rootScope) {
        var day  = '',
            days = '',
            error = function() {
                $rootScope.$emit('notify', {
                    message: 'default.notify.ERRORFILTERDAY',
                    status: 'danger'
                });
            };

        $translate('default.day.ONE').then(function(res) {
           day = res;
        }, function(error) {
            error();
        });

        $translate('default.day.MORE').then(function(res) {
            days = res;
        }, function(error) {
            error();
        });

        return function (input) {
            if(parseInt(input) == 1) {
                return input + ' ' + day;
            } else {
                return input + ' ' + days;
            }
        };
    }
]);