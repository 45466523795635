angular.module('eOpti.filters').filter('plus', ['$filter',
    function ($filter) {
        return function (input, none) {
            if(typeof none !== 'undefined' && parseInt(input) === parseInt(none)) {
                return 'brak';
            }

            return (input >= 0 ? '+' : '') + $filter('number')(input, 2);
        }
    }
]);