angular.module('eOpti.filters').filter('sum', [function () {
    return function (object, field) {
        var sum = 0;

        angular.forEach(object, function(obj) {
            sum += obj[field];
        });

        return sum;
    }
}
]);