angular.module('eOpti.filters.select').filter('range', [function () {
    return function (tab, option) {
        var result = [];

        if(typeof option === 'undefined') {
            option = 1;
        }

        for(var i = tab[0]; i <= tab[1]; i += option) {
            result.push(i);
        }

        return result;
    }
}
]);