angular.module('eOpti.filters').filter('filterSelectedDamages', [
    function () {
        var findCommonElements = function(arrays) {
            if(!arrays.length) {
                return [];
            }

            var currentValues = {},
                commonValues = {};

            for(var i = arrays[0].length-1; i >=0; i--) {
                currentValues[arrays[0][i]] = 1;
            }

            for(var i = arrays.length-1; i>0; i--) {
                var currentArray = arrays[i];
                for (var j = currentArray.length-1; j >=0; j--) {
                    if (currentArray[j] in currentValues) {
                        commonValues[currentArray[j]] = 1;
                    }
                }

                currentValues = commonValues;
                commonValues = {};
            }
            return Object.keys(currentValues).map(function(value) {
                return parseInt(value);
            });
        };

        return function (items, damages) {
            var _tab = [],
                filtered = [];

            damages.forEach(function(damage) {
                if(Object.keys(damage.obj).indexOf('specialists') > -1) {
                    _tab.push([]);

                    damage.obj.specialists.forEach(function(specialist) {
                        _tab[_tab.length - 1].push(specialist.id);
                    });
                }
            });

            var specialist_ids = findCommonElements(_tab);

            if(!specialist_ids.length || damages.length === 1) {
                filtered = items;
            } else {
                items.forEach(function(item) {
                    var _found = false;

                    item.specialists.forEach(function(specialist) {
                        if(specialist_ids.indexOf(specialist.id) > -1 && !_found) {
                            filtered.push(item);

                            _found = true;
                        }
                    });
                });
            }

            return filtered;
        }
    }
]);
