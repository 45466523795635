angular.module('eOpti.filters').filter('orderObjectBy', [
    function () {
        return function (items, field, reverse, keyName) {
            var filtered = [];

            angular.forEach(items, function(item, key) {
                filtered.push(item);

                if(keyName) {
                    filtered[filtered.length - 1][keyName] = key;
                }
            });

            filtered.sort(function(a, b) {
                return a[field] > b[field] ? 1 : -1;
            });

            if(reverse) {
                filtered.reverse();
            }

            return filtered;
        }
    }
]);