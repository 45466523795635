angular.module('eOpti.filters').filter('joinDot', [
    function () {
        return function (items, field) {
            if (angular.isArray(items)) {
                var out = [];

                items.forEach(function (item) {
                    out.push(item[field]);
                });

                return out.join(', ');
            }

            return items;
        }
    }
]);
