angular.module('eOpti.filters').filter('receipt', [
    function () {
        return function(input) {
            if(input && input.includes('/')) {
                return input.substr(input.indexOf('/') + 1)
            }

            return input
        }
    }
]);