angular.module('eOpti.filters').filter('initial', [function () {
    return function (input) {
        if (typeof input === 'string' && input.length > 1) {
            var tab = input.split(' ');

            tab[0] = tab[0].charAt(0) + '.';

            return (tab[0] + ' ' + tab[1]).trim();
        }

        return input;
    };
}]);