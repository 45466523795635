angular.module('eOpti.filters.time').filter('stringTime', ['$translate', '$rootScope',
    function ($translate, $rootScope) {
        let yesterday  = '',
            today = '',
            error = () => {
                $rootScope.$emit('notify', {
                    message: 'default.notify.ERRORFILTERDAY',
                    status: 'danger'
                })
            }

        $translate('default.YESTERDAY').then(res => {
           yesterday = res
        }, () => {
            error()
        })

        $translate('default.TODAY').then(res => {
            today = res;
        }, () => {
            error()
        })

        return function (input) {
            if(!input) {
                return input
            }

            let split = input.split(' '),
                objDate = {
                    y: parseInt(split[0].substring(0, 4)),
                    m: parseInt(split[0].substring(5, 7)) - 1,
                    d: parseInt(split[0].substring(8, 10))
                },
                date = new Date(objDate.y, objDate.m, objDate.d),
                current = new Date(),
                back = new Date()

            back.setDate(current.getDate() - 1)

            let year = date.getFullYear(),
                month = date.getMonth(),
                day = date.getDate(),

                c = {
                    year: current.getFullYear(),
                    month: current.getMonth(),
                    day: current.getDate()
                },

                y = {
                    year: back.getFullYear(),
                    month: back.getMonth(),
                    day: back.getDate()
                }

            if(year === c.year && month === c.month && day === c.day) {
                return today + (split.length === 2 ? (' ' + split[1]) : '')
            } else if(year === y.year && month === y.month && day === y.day) {
                return yesterday + (split.length === 2 ? (' ' + split[1]) : '')
            }

            return input
        }
    }
])