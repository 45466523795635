angular.module('eOpti.filters').filter('propsFilter', [
    function () {
        return function (items, props) {
            let out = []

            if(angular.isArray(items)) {
                items.forEach(item => {
                    let itemMatches = false,
                        keys = Object.keys(props)

                    for(let i = 0; i < keys.length; i++) {
                        let prop = keys[i],
                            text = props[prop].toLowerCase()

                        if(typeof item[prop] !== 'undefined' && item[prop].toString().toLowerCase().includes(text)) {
                            itemMatches = true

                            break
                        }
                    }

                    if(itemMatches) {
                        out.push(item)
                    }
                })
            } else {
                // Let the output be the input untouched
                out = items
            }

            return out
        }
    }
])