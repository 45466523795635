angular.module('eOpti.filters').filter('sign', [
    function () {
        return function (input) {
            if(input === null) {
                return '';
            }

            return (input >= 0 ? '+' : '') + input;
        }
    }
]);