angular.module('eOpti.filters').filter('ownDepartment', ['uac', function (uac) {
    return function(departments) {
        var result = [],
            allow = [];

        angular.forEach(uac.user.department, function(d) {
            allow.push(d.departmentId);
        });

        angular.forEach(departments, function(department) {
            if(allow.indexOf(department.id) !== -1) {
                result.push(department);
            }
        });

        return result;
    };
}]);
