angular.module('eOpti.filters').filter('fromSelect', [
    function () {
        return function (input, select, field, as, defaultValue) {
            var found = typeof defaultValue != 'undefined' ? defaultValue : '';
            angular.forEach(select, function (item) {
                if (item[field] == input) {
                    found = item[as];
                }
            });
            return found;
        }
    }
]);